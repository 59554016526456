import React, { Fragment } from 'react';

function Error404(props) {
    return (
        <Fragment>
            <div className="appointment_upper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="header_main d-md-flex justify-content-between align-items-center">
                            <a href="/"><img src="/images/logo.png" alt=""/></a>
                            {/* <div className="contact_info">
                                <h6>Contact Us<span><img src="/images/send_icon.png" alt=""/> support@fedbenefitspecialists.com</span></h6>
                            </div> */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our_services_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="mb-0 text-primar">404</h2>
                            <h2 className="mt-0">Page not found</h2>
                            <hr className="w-25" />
                            <p className="text-secondary">The link is broken or the page has been moved.</p>
                            <a className="btn btn-outline-primary" href="/">Back to Home</a>
                        </div>
                    </div>
                    <div className="row"></div>
                </div>
            </div>
            <div className="footer_main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h6>Copyright © 2021. Benefit Specialists, LLC. All Rights Reserved.</h6>
                            <p>Benefit Specialists, LLC is not affiliated with, endorsed, or sponsored by the Federal Government or any U.S. Government agency.   If you setup an appointment, the person you will be speaking with is independent and is not employed by Benefit Specialists, LLC, or the Federal Government or any U.S. Government agency. By setting up an appointment you agree to the following terms of use.  Benefit Specialists, LLC is a marketing company, and is not a financial advisor, broker dealer, investment advisory firm, insurance company, insurance agent, or insurance agency and does not provide investments, insurance related products, recommendations, financial advice, or tax advice in any way.</p>
                            <h6> <a href="/term-of-use">Terms of Use</a> | Contact Us : support@fedbenefitspecialists.com</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Error404;