import React, { Fragment } from 'react';

function Home(props) {
    return (
        <Fragment>
            <div className="appointment_upper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header_main d-md-flex justify-content-between align-items-center">
                                <a href="/"><img src="/images/logo.png" alt="" /></a>
                                {/* <div className="contact_info">
                                    <h6>Contact Us<span><img src="/images/send_icon.png" alt="" /> support@fedbenefitspecialists.com</span></h6>
                                </div> */}
                            </div>
                            <div className="hero_text">
                                <h1 className="mb-3">FEDERAL BENEFITS & <br />
                                    RETIREMENT INCOME
                                </h1>
                                {/* <ul>
                                    <li><a href="#">Federal employees</a></li>
                                    <li><a href="#">Postal employees</a></li>
                                    <li><a href="#">State/municipal employees</a></li>
                                    <li><a href="#">Teachers</a></li>
                                </ul> */}
                                <a className="mt-3 setup_button" href="/setup-an-appointment">Schedule a Free Consultation</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our_services_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>ASK QUESTIONS ABOUT:</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h3>Benefits</h3>
                            <ul>
                                <li><img src="/images/tick_icon.png" alt="" /> FERS Pension</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Social Security</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Spousal Benefit</li>
                                <li><img src="/images/tick_icon.png" alt="" /> FERS Supplement </li>
                                {/* <li><img src="/images/tick_icon.png" alt="" /> Generate tax-free income</li> */}
                            </ul>
                        </div>
                        <div className="col-md-5">
                            <h3>Thrift Savings Plan</h3>
                            <ul>
                                <li><img src="/images/tick_icon.png" alt="" /> TSP Modernization Act</li>
                                <li><img src="/images/tick_icon.png" alt="" /> SECURE Act</li>
                                {/* <li><img src="/images/tick_icon.png" alt="" /> Individual Retirement Accounts (IRA)</li>
                                <li><img src="/images/tick_icon.png" alt="" /> 401k</li>
                                <li><img src="/images/tick_icon.png" alt="" /> 403b</li> */}
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3 className="mx-4">Insurance </h3>
                            <ul className="mx-4">
                                <li><img src="/images/tick_icon.png" alt="" /> FEGLI (Life Insurance)</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Long Term Care</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Disability</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h6>Copyright © 2021. Benefit Specialists, LLC. All Rights Reserved.</h6>
                            <p>Benefit Specialists, LLC is not affiliated with, endorsed, or sponsored by the Federal Government or any U.S. Government agency.   If you setup an appointment, the person you will be speaking with is independent and is not employed by Benefit Specialists, LLC, or the Federal Government or any U.S. Government agency. By setting up an appointment you agree to the following terms of use.  Benefit Specialists, LLC is a marketing company, and is not a financial advisor, broker dealer, investment advisory firm, insurance company, insurance agent, or insurance agency and does not provide investments, insurance related products, recommendations, financial advice, or tax advice in any way.</p>
                            <h6> <a href="/term-of-use">Terms of Use</a> | Contact Us : support@fedbenefitspecialists.com</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Home;