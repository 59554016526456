import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { Typography } from '@mui/material';
import './style.scss';
import moment from 'moment';
import minusExcept from '../../utils/array';

export default function Calender({
  dateSelected,
  setDateSelect,
  dates,
  blockTillDate,appointmentCount
}) {

  console.log("blocked?::",blockTillDate);

  const [restrictedDate, setRestrictedDate] = useState(new Date());
  const [blockeddate, setBlockedDate] = useState(blockTillDate);
  console.log("restricted::",restrictedDate);

  useEffect(()=>{
    let startDate = new Date();
    var strtDt = new Date(moment(blockTillDate));
    strtDt.setDate(strtDt.getDate() + 1)
    startDate.setDate(startDate.getDate() + 2);

    setRestrictedDate(startDate > strtDt ? startDate : strtDt)
    setBlockedDate(blockTillDate)
  }, [blockTillDate])

  const timeArray = [
    "10:00,"+appointmentCount, "10:30,"+appointmentCount,
    "11:00,"+appointmentCount, "11:30,"+appointmentCount,
    "12:00,"+appointmentCount, "12:30,"+appointmentCount,
    "09:00,"+appointmentCount, "09:30,"+appointmentCount,
    "13:00,"+appointmentCount, "13:30,"+appointmentCount,
    "14:00,"+appointmentCount, "14:30,"+appointmentCount,
    "15:00,"+appointmentCount, "15:30,"+appointmentCount,
    "16:00,"+appointmentCount, "16:30,"+appointmentCount,
    "17:00,"+appointmentCount
  ]

  const timeArrayFull = [
    "17:30,"+appointmentCount,
    "18:00,"+appointmentCount, "18:30,"+appointmentCount,
    "19:00,"+appointmentCount,
  ];

  const handleDateChange = (date) => {
    setDateSelect(date)
  };

  const disableDates = (date) => {
    if(date.getDay() === 0) return true
    else if(date.getDay() === 6 ) return true
    else if (moment(date.toString()).format('YYYY-MM-DD') < moment(restrictedDate.toString()).format('YYYY-MM-DD')) return true
    return !checkAvailable(date)
  }

  const checkAvailable = (date) => {
    let d = moment(date.toString()).format('YYYY-MM-DD');
    let arr = [];
    if(dates)
      arr = dates[d] ?? []
    if(date.getDay() == 5){
      if((minusExcept(timeArray,arr)).length)
        return true;
    } else {
      if((minusExcept(timeArray.concat(timeArrayFull),arr)).length)
        return true;
    }
    return false;
  }

  return (
    <>
      <Typography variant="h6" fontWeight="bold" sx={{ fontStyle: 'normal' }}>
        Select a Date & Time
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CalendarPicker
          className={'agent_register_calendar'}
          date={dateSelected}
          minDate={restrictedDate}
          onChange={handleDateChange}
          disablePast
          defaultCalendarMonth={new Date(blockTillDate)}
          shouldDisableDate={disableDates}
          views={['day']}
        />
      </LocalizationProvider>
    </>
  );
}
