import React from 'react';
import moment from 'moment';
import Qs from "qs";
import { Typography,Box } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PublicIcon from '@mui/icons-material/Public';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

function Confirmed(props) {
    const dateO = moment(props.dateSelected).format('ddd, MMM DD, YYYY')
    const param = {
        text: "Agent Qualifying Phone Meeting",
        //   details: "You are scheduled with Federal Benefit Specialists",
        details: "",
        dates: dateO + "/" + dateO,
        ctz: props.timezone,
    }
    const url = "https://calendar.google.com/calendar/u/0/r/eventedit?" + Qs.stringify(param);

    function addDashes(f) {
        return f.slice(0, 3) + "-" + f.slice(3, 6) + "-" + f.slice(6);
    }
    const st = {
        display: "flex",
        marginTop: '20px',
        justifyContent: 'start',
        alignContent: "center",
        color: "#595959",
        fontStyle: "normal",
       
    }

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-md-12">
                    <div className="">
                        <div className="row">
                            <div className="col-md-6 m-auto text-center">
                                <div className="meeting_info_card confirm_meeting_card text-center">
                                    <div className="company_logo">
                                        <img src="./images/fbs_logo.png" alt="" />
                                        <Typography variant="h1" gutterBottom mt-2 style={{ fontWeight: 700, color: "#747474", display: "block" }}>
                                            Appointment Confirmed
                                        </Typography>
                                    </div>
                                    {/* <p>You are scheduled with Federal Benefit Specialists.</p> */}
                                    <a className="add_to_calendar" href={url} target="_blank">
                                        <img src="./images/plus_icon_6.png" alt="" />
                                        <Typography paragraph gutterBottom mt-2 style={{ color: "#007bff", backgroundColor: "transparent" }}>
                                            Add to your calendar
                                        </Typography>
                                    </a>
                                    <div className="confirm_meeting_border text-center">
                                        <div><img src="./images/fade_border.png" alt="" /></div>
                                        <Typography variant="h1" gutterBottom mt-2 style={{ fontWeight: 700, color: "#747474", display: "block" }}>
                                            Federal Employee Complimentary<br />Phone Meeting
                                        </Typography>
                                        <Box sx ={{display:'grid',justifyContent:'center'}}>

                                        
                                        <Box sx={{display:'flex', justifyContent:  'start', alignItems:'center', gap:1}}>
                                        <Box sx={st} >
                                            <WatchLaterIcon color={"primary"} />
                                            <Typography>
                                                30 min
                                            </Typography>
                                        </Box>
                                        <Box sx={st}>
                                            <PhoneInTalkIcon color={"primary"} />
                                            <Typography>
                                            Phone Call
                                            </Typography>
                                        </Box>
                                        </Box>
                                        {
                                            props.date &&
                                            <Box sx={st}>
                                                <CalendarMonthIcon color={"success"} />
                                                <Typography>
                                                    {
                                                        props.time && props.time
                                                    } {moment(props.date).format('ddd, MMM DD, YYYY')}
                                                </Typography>
                                            </Box>
                                        }
                                        {
                                        props.timezone &&
                                        <Box sx={st}>
                                            <PublicIcon color={"success"} />
                                            <Typography>
                                                {props.timezone}
                                            </Typography>
                                        </Box>

                                        }
                                        </Box>
                                        <div><img src="./images/fade_border.png" alt="" /></div>
                                    </div>
                                </div>
                                <div className="automated_scheduling">
                                    {/* <label>Simple. Automated. Scheduling.</label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Confirmed