import { useState, useEffect } from 'react';
import moment from 'moment';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Paper, Box, Button, Typography} from '@mui/material';
import AppointmentDetail from './Calendar/Components/AppointmentDetail';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import Error404 from './Error404';
//components
import Calender  from './Calendar/Components/Calender'
import TimerView from './Calendar/Components/TimerView';
import Confirmed from './Calendar/Components/Confirmed';
import Loading from '../components/Loading';

//const endpoint_url = "http://calendar.local";
const endpoint_url = window.location.origin;

function ReSchedule() {
  const hasWindow = typeof window !== 'undefined';
  const [date, setDate] = useState(null); 
  const [dates, setDates] = useState([]); 
  const [dateObject, setDateObject] = useState(null); 
  const [formatDate, setFormatDate] = useState(null); 
  const [format2Date, setFormat2Date] = useState(null); 
  const [timezone, setTimezone] = useState(null); 
  const [time, setTime] = useState(null);
  const [phone, setPhone] = useState(null);
  const [appointmentCount, setAppointmentCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [exists, setExists] = useState(false);
  const [blockTillDate, setBlockTillDate] = useState("2022-01-01"); 

  const [step, setStep] = useState(1);
  const [timezoneSelected,setTimezoneSelected] = useState('Pacific Time (PT)');
  const [timeSelected, setTimeSelect] = useState(null);
  const [dateSelected,setDateSelect] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [isDesktop, setIsDesktop] = useState(getWindowDimensions().width > 1100);
  const [isTablet,setIsTablet] = useState(getWindowDimensions().width >= 600 && getWindowDimensions().width <= 1100);
  const [isMobile,setIsMobile] = useState(getWindowDimensions().width < 600);

  const [step1, setStep1] = useState(true);


  useEffect(() => {
    if (hasWindow) {
        function handleResize() {
            setIsDesktop(getWindowDimensions().width > 1100);
            setIsTablet(getWindowDimensions().width >= 600 && getWindowDimensions().width <= 1100);
            setIsMobile(getWindowDimensions().width < 600);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
          width,
          height,
      };
  }
  useEffect(() => {
    getDates();
    loadInit();
  }, []);

  const style = {
    rightPanel: {display:"flex", justifyContent:"center", alignItem:"center", p:5, width:'100%'}
  }
  // create constructor.
  const loadInit = () => {
    // set loading to true
    setLoading(true);
    const id = getQueryString("id");
    const email = getQueryString("email");
    var formData = new FormData();
    formData.append('id', id);
    formData.append("email", email);

    axios({
      url:endpoint_url+"/checkExists.php",
      method:"POST",
      data: formData,
    }).then((response)=>{
      if(response.data == true) {
        setExists(true);
      }
      setLoading(false)
    }).catch((error)=>{
      // nothing...
      setLoading(false)
    });
  }
  const _handleDateSelect = (date) => {
    setDate(moment(date.toString()).format('YYYY-MM-DD'));
    setDateObject(date);
    setFormatDate(moment(date.toString()).format('dddd, MMMM DD, YYYY'));
    setFormat2Date(moment(date.toString()).format('ddd, MMM DD'));
    setTime(null)
  }

  // function to get query string from url.
  function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }
  const getDates = async () => {
    setLoading(true)
    axios({
    url:endpoint_url+"/get-date.php",
    method:"GET",
    headers:{
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }}).then((response)=>{
      setDates(response.data.data)
      setAppointmentCount(response.data.appointment_count)
      setBlockTillDate(response.data.block_till_date)
    }).catch((error)=>{
      setDates([]);
    });
    setLoading(false)
  }

  const _handleTimezoneSelect = (timezone) => {
    setTimezone(timezone);
  }

  const _handleTimeSelect = (time) => {
    setTime(time)
  }

  const _handleConfirm = (flag) => {
    if(date === null){
      NotificationManager.warning('Warning', 'Please select date first!', 3000);
      return;
    }
    if(time === null){
      NotificationManager.warning('Warning', 'Please select time first!', 3000);
      return;
    }
    if(timezone === null){
      NotificationManager.warning('Warning', 'Please select timezone first!', 3000);
      return;
    }
    setLoading(true)
      // get id from url parameter
      const id = getQueryString("id");
      const email = getQueryString("email");
      var formData = new FormData();
      formData.append('id', id);
      formData.append("date", date + " " + time);
      formData.append("timezone", timezone);
      formData.append("email", email);
      // submit
      axios({
        url: endpoint_url+"/reschedule.php",
        method:"POST",
        data:formData
    }).then((response)=>{
      setLoading(false);
        if(response.data.success){
          setStep1(false)

          NotificationManager.success('Success', 'Updated Success!', 3000);
  
        } else {
            NotificationManager.error('Unable to Submit', 'Please refresh and try again!', 3000);
        }
        //setLoading(false)
    }).catch((error)=>{
        console.log(error);
        // setLoading(false)
    });
  }

  const onClickBack = () => {
    setStep1(true)
  }

  const _handleSubmit = (phone) => {
    //pass 
  }

  return (
    <>
     <Paper sx={
                isDesktop ? 
                { 
                    width: dateSelected == null ? "60%" : "65%", 
                    display:"flex", justifyContent:"start", alignItem:"center", 
                    my:5, mx:'auto',
                    border: '1px solid #d3d3d3',
                    backgroundColor:"#f0f0f0",
                    position:"relative"
                }
                : { width:"90%", alignItem:"center", mt:5, mx:'auto', backgroundColor:"#f0f0f0", position:"relative"}
            }>
               
                <Loading isLoading={loading}/> 
                {step == 2 ?
                 <Confirmed time={timeSelected} date={dateSelected} timezone={timezoneSelected} phone={phone}  isMobile={isMobile}/>
                 :
                 <>
                  <Box sx={{px:isMobile ? 0 : 5, width: isMobile || isTablet ? '100%' : '60%'}}>
                    <AppointmentDetail 
                        step={step} 
                        isDesktop={isDesktop}
                        isTablet={isTablet}
                        isMobile={isMobile}
                        setStep={setStep}
                        dateSelected={dateSelected}
                        timezoneSelected={timezoneSelected}
                        timeSelected={timeSelected}
                    />
                </Box>
                
                {/* Mobile View */}
                <Box 
                    sx={ 
                        isDesktop 
                        ? { ...style.rightPanel, borderLeft: '0.1px solid #cdcdcd' } 
                        : { ...style.rightPanel, borderTop: '0.1px solid #cdcdcd' } 
                    }>
                    {
                        <>
                            {
                                (isDesktop || isTablet || (isMobile && dateSelected == null)) && <Box>
                                    { !loading &&  <Calender 
                                        dateSelected={dateSelected}
                                        setDateSelect={setDateSelect}
                                        isMobile ={isMobile}
                                        dates={availableDates} 
                                        appointmentCount={appointmentCount}
                                        blockTillDate={blockTillDate}
                                    />}
                                    
                                </Box>
                            }
                            {/* Todo: If date selected then show the timer */}
                            {
                                dateSelected && 
                                <Box width={'100%'}>
                                    { isMobile &&
                                        <Button fullWidth variant="contained" color={"success"} sx={{mb:1}} onClick={()=>{setDateSelect(null)}}>
                                            <ArrowBackIcon /> &nbsp; Back
                                        </Button>
                                    }
                                    { !loading && <TimerView 
                                        timezoneSelected={timezoneSelected} 
                                        setTimezoneSelected={setTimezoneSelected}
                                        timeSelected={timeSelected}
                                        setTimeSelect={setTimeSelect}
                                        setStep={setStep}
                                        dateSelected={dateSelected}
                                        setDateSelect={setDateSelect}
                                        blocked={availableDates[moment(dateSelected.toString()).format('YYYY-MM-DD')]}
                                        isMobile={isMobile} /> }
                                </Box>
                            }
                        </> 
                       
                      
                    }
                </Box>
                 </>
                  
              }
               
            </Paper>
            <Box sx={{display:isMobile ? "": "flex", textAlign:"center", justifyContent:'center', alignItems:'center', gap:0.5, mb:3}}>
                <Typography>Copyright © {new Date().getFullYear()}.</Typography>
                <Typography color={'primary'}>Benefit Specialists, LLC.</Typography> 
                <Typography>All Rights Reserved</Typography>
            </Box>
    </>
  );
}

export default ReSchedule;