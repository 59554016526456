import React from 'react'
import {Button, Box} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { textAlign } from '@mui/system';
const timzones = [
    'Eastern Time (ET)',
    'Central Time (CT)',
    'Mountain Time (MT)',
    'Pacific Time (PT)',
]

const timzonesShow = {
    'Eastern Time (ET)': "Eastern Time",
    'Central Time (CT)': "Central Time", 
    'Mountain Time (MT)': "Mountain Time", 
    'Pacific Time (PT)': "Pacific Time", 
}

const styleButton = {
    mt:1,
    fontSize: '10px',
    position: "relative",
    justifyContent: "end"
}

const styleIcon = {
    position: "absolute",
    left: '5px'
}

const ButtonTimeZone = ({timezoneSelected,setTimezoneSelected}) => {
    return (
        <Box sx={{px:2, pb:1, backgroundColor:'#e7e6e6', borderBottom:'1px solid #1976d2'}}>
        {
            timzones.map(
                (tz,index) => {
                    return (
                        <Button onClick={()=>{
                            setTimezoneSelected(tz)
                        }} 
                        key={index} 
                        variant={timezoneSelected === tz ? "contained" : "outlined"} 
                        fullWidth 
                        sx={styleButton}
                        >
                            {timezoneSelected === tz ? <CheckBoxIcon sx={styleIcon}/> : <CheckBoxOutlineBlankIcon sx={styleIcon}/>}
                            {timzonesShow[tz]}
                        </Button>
                    )
                }
            )
        }
        </Box>
    )
}

export default ButtonTimeZone;